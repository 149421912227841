import { Head } from 'components/common';
import { Privacy20230901 } from 'components/policy';
import PolicyLayout from 'layout/policy-layout';

const PolicyPrivacy = () => {
  return (
    <PolicyLayout>
      <Head title="개인정보처리방침" />
      <Privacy20230901 />
    </PolicyLayout>
  );
};

export default PolicyPrivacy;
